<template>
  <div class=" w-full m-auto lg:w-9">
    <div class="grid">
      <!-- <div class="surface-section px-4 py-5 md:px-6 lg:px-8" style="margin-left: -4rem;margin-right: -4rem;margin-top: -2rem;">
    <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
        <li>
            <a class="text-500 no-underline line-height-3 cursor-pointer">Application</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500 line-height-3"></i>
        </li>
        <li>
            <span class="text-900 line-height-3">Analytics</span>
        </li>
    </ul>
    <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
        <div>
            <div class="font-medium text-3xl text-900">Customers</div>
            <div class="flex align-items-center text-700 flex-wrap">
                <div class="mr-5 flex align-items-center mt-3">
                    <i class="pi pi-users mr-2"></i>
                    <span>332 Active Users</span>
                </div>
                <div class="mr-5 flex align-items-center mt-3">
                    <i class="pi pi-globe mr-2"></i>
                    <span>9402 Sessions</span>
                </div>
                <div class="flex align-items-center mt-3">
                    <i class="pi pi-clock mr-2"></i>
                    <span>2.32m Avg. Duration</span>
                </div>
            </div>
        </div>
        <div class="mt-3 lg:mt-0">
            <Button label="Add" class="p-button-outlined mr-2" icon="pi pi-user-plus"></Button>
            <Button label="Save" icon="pi pi-check"></Button>
        </div>
    </div>
</div> -->

      <div
        v-if="state == states.tutorial"
        class="surface-section px-4 py-8 md:px-6 lg:px-8 col-12"
      >
        <tutorial @next="state = states.upload" />
      </div>

      <div v-if="state == states.upload" class="col-12">
        <div class="text-700 text-center my-3">
          <div class="text-900 font-bold text-5xl">Upload Photo</div>
        </div>

        <div class="surface-section px-4 py-5 md:px-6 lg:px-8 col-12">
          <div class="text-700 text-center">
            <upload-form
              @next="
                generated = $event;
                state = states.gallery;
              "
            />
          </div>

          
        </div>
      </div>

      <image-gallery v-if="state == states.gallery" :generated="generated" />

      <!-- <div v-if="true" class="surface-card p-4 shadow-2 border-round mt-5 col-6">
      <div class="text-3xl font-medium text-900 mb-3">Upload Photo</div>
      <div class="font-medium text-500 mb-3"></div>

      
    </div> -->
    </div>
  </div>
</template>

<script>
import CountryService from "../service/CountryService";
import UploadForm from "./h/UploadForm.vue";
import Tutorial from "./h/Tutorial.vue";
import ImageGallery from "./h/ImageGallery.vue";

export default {
  components: {
    UploadForm,
    Tutorial,
    ImageGallery,
  },
  data() {
    return {
      state: "tutorial",
      states: {
        tutorial: "tutorial",
        upload: "upload",
        gallery: "gallery",
      },
      generated: null,

      countries: [],
      filteredCountries: null,
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      value7: null,
      value8: null,
      value9: null,
      value10: null,
    };
  },
  created() {
    this.countryService = new CountryService();
  },
  mounted() {
    // this.countryService.getCountries().then((countries) => {
    //   this.countries = countries;
    // });
  },
  methods: {
    searchCountry(event) {
      // in a real application, make a request to a remote url with the query and
      // return filtered results, for demo we filter at client side
      const filtered = [];
      const query = event.query;
      for (let i = 0; i < this.countries.length; i++) {
        const country = this.countries[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
        }
      }
      this.filteredCountries = filtered;
    },
  },
};
</script>
