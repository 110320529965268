<template>
  <div class="hello">
    <div v-if="isLoading" class="my-5">
      <ProgressSpinner />
      <h2>{{isGenerating ? "Generating Images..." : "Uploading..."}}</h2>
    </div>
    <!--UPLOAD-->
    <div v-if="!isLoading">
      <form enctype="multipart/form-data" novalidate>
        <div class="dropbox border-2 border-dashed surface-border">
          <input
            type="file"
            multiple
            name="uploadFieldName"
            :disabled="false"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="image/*"
            class="input-file"
          />
          <p>
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <!-- <p >
              Uploading {{ fileCount }} files...
            </p> -->
        </div>
      </form>

      <img :src="previewImage" />
      <br />
      <small
        ><i class="pi pi-info-circle"></i>&nbsp;We take your privacy seriously. All uploads are encrypted.</small
      >
    </div>
  </div>
</template>

<script>
import * as axios from "axios";

export default {
  name: "UploadInput",
  props: {
    msg: String,
  },
  data() {
    return {
      fileCount: 0,
      previewImage: null,
      generatedImages: null,
      isLoading: false,
      isGenerating: false,
    };
  },
  mounted() {
    console.log("router", this.$router);
  },
  methods: {
    async filesChange(_, fileList) {
      const formData = new FormData();
      console.log(fileList);

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("files", fileList[x], fileList[x].name);
      });

      if (this.$route.query.ref) {
        formData.append("ref", this.$route.query.ref);
      }

      this.display(fileList[0]);

      this.isLoading = true;

      setTimeout(() => {
        this.isGenerating = true;
        this.$forceUpdate();

      }, 5000);

      var result = await axios.post("/api/upload", formData);

      

      if (result.data.error) {
        console.log("error", result.data.error);
        return;
      }

      console.log(result.data);
      this.generatedImages = result.data.generatedImages;

      this.$router.push({
        name: "demo-gallery",
        params: { demoId: result.data.demoId },
        query: this.$route.query,
      });
    },
    display(file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
.dropbox {
  background-color: #f8fafcd9;
  cursor: pointer;
  outline-offset: 5px !important;
  position: relative;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
}

/* .dropbox:hover {
  background: lightblue; 
} */

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
