<template>
  <div class="text-700 text-center">
        <!-- <div class="text-blue-600 font-bold mb-3"><i class="pi pi-tag"></i>&nbsp;FREE DEMO</div> -->
        <div class="text-900 font-bold text-5xl mb-3">
          You're almost ready to create your headshot
        </div>
        <div class="text-700 text-2xl my-5">
          Follow the steps below to ensure your headshot comes out with the best
          possible quality.
        </div>
        <div class="grid mb-3">
          <div class="col-12 lg:col-6 xl:col-3 p-5">
            <i class="pi pi-sun" style="font-size: 2rem"></i>
            <h4 class="mt-3"><b>Use Natural Light</b></h4>
            <p class="mb-0">
              For best results, avoid artificial light and face towards an open
              window. Finding the right light can make a huge impact on the
              final result.
            </p>
          </div>
          <div class="col-12 lg:col-6 xl:col-3 p-5">
            <i class="pi pi-image" style="font-size: 2rem"></i>
            <h4 class="mt-3"><b>Avoid Cluttered Backgrounds</b></h4>
            <p class="mb-0">
              Our AI is amazing at removing backgrounds, but choosing a photo
              spot with a neutral background color and less clutter will reduce
              chance of error.
            </p>
          </div>
          <div class="col-12 lg:col-6 xl:col-3 p-5">
            <i class="pi pi-user" style="font-size: 2rem"></i>
            <h4 class="mt-3"><b>Pose Comfortably</b></h4>
            <p class="mb-0">
              Rotate your body slightly away from the camera and point your face
              directly in the camera. <br />
              Also, don't forget to <b>smile</b>!
            </p>
          </div>
          <div class="col-12 lg:col-6 xl:col-3 p-5">
            <i class="pi pi-camera" style="font-size: 2rem"></i>
            <h4 class="mt-3"><b>Camera Placement</b></h4>
            <p class="mb-0">
              Turn your phone horizontally to take a Landscape picture. Make
              sure the camera is eye level, and stand back so the there is some
              room above your head and the bottom of the image stopping at your
              waist.
            </p>
          </div>
        </div>
        <small
          ><i class="pi pi-users"></i>Tip: Have someone else take the picture or
          place your phone in the window on a timer. Do not hold the camera
          yourself and do not use front-facing camera.</small
        ><br />
        <Button
        @click="$emit('next')"
          label="Go To Photo Upload"
          icon="pi pi-arrow-circle-right"
          class="
            font-bold
            px-5
            py-3
            p-button-raised p-button-rounded
            white-space-nowrap
            mt-6
          "
        ></Button>
      </div>
</template>

<script>
export default {
  props: []
}
</script>
